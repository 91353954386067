import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { pink } from '@mui/material/colors';
import Button from "@mui/material/Button";
import ButtonAppBar from "./ButtonAppBar";
import {useEffect, useState} from "react";
import {auth, logout} from "../firebase";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from "@mui/material/TextField";
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {CircularProgress} from "@mui/material";
import axios from "axios";
import CheckIcon from '@mui/icons-material/Check';
import ToggleButton from '@mui/material/ToggleButton';


function Home() {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [rowData, setRowData] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [showValid, setShowValid] = React.useState(false);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    }, [user, loading]);

    function createData(id, email, status) {
        return { id, email, status };
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const dataList = data.get('emailList');
        const list = dataList.split('\n');
        let auxData = [];
        setProcessing(true);
        setRowData([]);
        let urlList = [];
        for (let i = 0; i < list.length; i++) {
            const item = list[i];
            if (!validSyntax(item)) {
                auxData = [...auxData, createData(i, item, "INVALID")];
                setRowData(auxData);
            } else {

                const domain = item.split('@')[1];
                const url = "https://dns.google/resolve?name=" + domain + "&type=MX";
                urlList.push({url: url, index: i, item: item});
            }
        }

        await mapConcurrent(urlList, 2, async function (item) {
            await new Promise(resolve => {
                setTimeout(async () => {
                    //console.log('sleeper');
                    await new Promise(resolve1 => {
                        axios.get(item.url).then((res) => {
                            //console.log(res.data)
                            if (res.data.Answer !== undefined) {
                                auxData = [...auxData, createData(item.index, item.item, "VALID")];
                                setRowData(auxData);
                            } else {
                                auxData = [...auxData, createData(item.index, item.item, "INVALID")];
                                setRowData(auxData);
                            }
                            resolve1();
                        })
                    })
                    resolve();
                }, 2000)
            })

        })

        setProcessing(false);
    };



    function mapConcurrent(items, maxConcurrent, fn) {
        let index = 0;
        let inFlightCntr = 0;
        let doneCntr = 0;
        let results = new Array(items.length);
        let stop = false;

        return new Promise(function(resolve, reject) {

            function runNext() {
                let i = index;
                ++inFlightCntr;
                fn(items[index], index++).then(function(val) {
                    ++doneCntr;
                    --inFlightCntr;
                    results[i] = val;
                    run();
                }, function(err) {
                    // set flag so we don't launch any more requests
                    stop = true;
                    reject(err);
                });
            }

            function run() {
                // launch as many as we're allowed to
                while (!stop && inFlightCntr < maxConcurrent && index < items.length) {
                    runNext();
                }
                // if all are done, then resolve parent promise with results
                if (doneCntr === items.length) {
                    resolve(results);
                }
            }

            run();
        });
    }

    function validSyntax(input) {
        const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return !!input.match(validRegex);
    }

    return (
        <>
            {loading ? <>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            </> : <>
                <ButtonAppBar logout={logout} />
                <div style={{padding: "50px"}}>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <br/>
                        <TextField
                            aria-label="empty textarea"
                            placeholder="Paste email list here (200 emails maximum!)"
                            multiline
                            minRows={10}
                            maxRows={20}
                            autoFocus
                            id={"emailList"}
                            required
                            fullWidth
                            name="emailList"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Validate
                        </Button>

                    </Box>

                    {processing ? <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box> : <></>}

                    <br/>
                    <ToggleButton
                        value="check"
                        selected={showValid}
                        onChange={() => {
                            setShowValid(!showValid);
                        }}
                    >
                        <CheckIcon />
                        Show Valid Emails Only
                    </ToggleButton>
                    <br/>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell >Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rowData.map((row) => (
                                    <>
                                        {showValid && (row.status === 'VALID') ? <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.email}
                                            </TableCell>
                                            <TableCell >
                                                <CheckCircleIcon color="success"/>
                                            </TableCell>
                                        </TableRow> : <></>
                                        }
                                        {!showValid ? <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.email}
                                            </TableCell>
                                            <TableCell >
                                                {row.status === 'NEW' ? <PendingIcon color="primary" /> : ""}
                                                {row.status === 'INVALID' ? <DangerousIcon sx={{ color: pink[500] }} /> : ""}
                                                {row.status === 'VALID' ? <CheckCircleIcon color="success"/> : ""}
                                            </TableCell>
                                        </TableRow> : <></>
                                        }
                                    </>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>}

        </>

    );
}

export default Home;
