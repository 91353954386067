import {
getAuth, signInWithEmailAndPassword, signOut,
} from "firebase/auth";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD82DG3aHlPfvoVCA_Hx8yF0930MradAIg",
  authDomain: "emailvalidator-1ecc6.firebaseapp.com",
  projectId: "emailvalidator-1ecc6",
  storageBucket: "emailvalidator-1ecc6.appspot.com",
  messagingSenderId: "675256927525",
  appId: "1:675256927525:web:4c578e87f2fb0cff6c06cc",
  measurementId: "G-DMJ482ZEQG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);


const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password).then((r) => {
      console.log(r)
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth).then(r => console.log(r));
};

export {
  auth,
  logInWithEmailAndPassword,
  logout,
};