import './App.css';
import SignInSide from "./components/SignInSide";
import Home from "./components/Home";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

function App() {
  return (
      <>
          <Router>
              <Routes>
                  <Route exact path="/" element={<SignInSide/>} />
                  <Route exact path="/validate" element={<Home/>} />
              </Routes>
          </Router>
      </>
  );
}

export default App;
